import { Illustration } from "@clipboard-health/ui-components";
import { Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export default function FriendsBlankState() {
  return (
    <Stack flex={1} direction="column">
      <Stack flex={1} />
      <Stack
        sx={{
          width: "100%",
          maxWidth: {
            xs: "20rem",
            sm: "25rem",
          },
          mx: "auto",
          px: 3,
        }}
        flex={1}
        alignItems="center"
      >
        <Stack>
          <Illustration type="mail-person" />
        </Stack>
        <Title
          component="h4"
          variant="h4"
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
          py={4}
          align="center"
        >
          Invite friends to see which open shifts they&apos;ll be working
        </Title>
      </Stack>
      <Stack flex={1} />
    </Stack>
  );
}
