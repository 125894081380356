import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { type ReactNode } from "react";

import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import { ProfileImage } from "./ProfileImage";

interface Props {
  friend: WorkerPublicProfile;
  actions: ReactNode;
}

export default function FriendRow(props: Props) {
  const { friend, actions } = props;

  return (
    <Stack direction="row">
      <Stack flex={1} flexDirection="row" alignItems="center" minWidth={0}>
        <Stack mr={4} flexShrink={0}>
          <ProfileImage friend={friend} />
        </Stack>
        <Stack minWidth={0} flex={1}>
          <Text
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {`${friend.attributes.firstName} ${friend.attributes.lastName}`}
          </Text>
          <Text
            variant="body2"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
          >
            {friend.attributes.qualifications[0]}
          </Text>
        </Stack>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={2} flexShrink={0} ml={2}>
        {actions}
      </Stack>
    </Stack>
  );
}
