import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import { useIsWorkWithFriendsV2Enabled } from "../hooks/useIsWorkWithFriendsV2Enabled";
import { ProfileImage } from "./ProfileImage";

interface Props {
  workers: WorkerPublicProfile[];
}

export function WorkTogetherStack(props: Props) {
  const { workers } = props;

  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();
  if (!isWorkWithFriendsV2Enabled) {
    return null;
  }

  return (
    <Stack flex={1} justifyContent="flex-end" flexDirection="row">
      {workers.slice(0, 2).map((worker) => (
        <ProfileImage
          key={worker.id}
          sx={{ marginRight: -3, border: "1px solid white" }}
          size="small"
          friend={worker}
        />
      ))}
      {workers.length > 2 && (
        <Stack
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            width: 40,
            height: 40,
            borderRadius: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginRight: -3,
          })}
        >
          <Text variant="body2" sx={{ color: "text.secondary", fontWeight: "bold" }}>
            +{workers.length - 2}
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
