import { Image, Text } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { Stack, type SxProps, type Theme } from "@mui/material";

import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";

const COLOR_PAIRS = [
  {
    backgroundColor: getTheme().palette.primary.light,
    textColor: getTheme().palette.primary.contrastText,
  },
  {
    backgroundColor: getTheme().palette.secondary.light,
    textColor: getTheme().palette.secondary.contrastText,
  },
  {
    backgroundColor: getTheme().palette.success.light,
    textColor: getTheme().palette.success.contrastText,
  },
  {
    backgroundColor: getTheme().palette.warning.light,
    textColor: getTheme().palette.warning.contrastText,
  },
  {
    backgroundColor: getTheme().palette.error.light,
    textColor: getTheme().palette.error.contrastText,
  },
  {
    backgroundColor: getTheme().palette.info.light,
    textColor: getTheme().palette.info.contrastText,
  },
  {
    backgroundColor: getTheme().palette.grey[300],
    textColor: getTheme().palette.grey[900],
  },
  {
    backgroundColor: getTheme().palette.primary.dark,
    textColor: getTheme().palette.primary.contrastText,
  },
] as const;

/**
 * Generates a consistent color pair from a MongoDB ObjectId
 */
function getColorPairFromObjectId(objectId: string): {
  backgroundColor: string;
  textColor: string;
} {
  const index = Number.parseInt(objectId[0], 16) % COLOR_PAIRS.length;
  return COLOR_PAIRS[index];
}

interface Props {
  friend: WorkerPublicProfile;
  size?: "small" | "large";
  sx?: SxProps<Theme>;
}

export function ProfileImage(props: Props) {
  const { friend, size = "small", sx } = props;

  if (!friend.attributes.profileImageUrl) {
    const { backgroundColor, textColor } = getColorPairFromObjectId(friend.id);
    return (
      <Stack
        width={size === "small" ? 40 : 128}
        height={size === "small" ? 40 : 128}
        borderRadius="100%"
        sx={{ backgroundColor, ...sx }}
        alignItems="center"
        justifyContent="center"
      >
        <Text variant={size === "small" ? "body1" : "h3"} sx={{ color: textColor }}>
          {`${friend.attributes.firstName.charAt(0)}${friend.attributes.lastName.charAt(0)}`}
        </Text>
      </Stack>
    );
  }

  return (
    <Image
      sx={{ borderRadius: "100%", ...sx }}
      src={friend.attributes.profileImageUrl}
      alt="Profile"
      width={size === "small" ? 40 : 128}
    />
  );
}
