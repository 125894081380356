import { getTheme } from "@clipboard-health/ui-theme";
import { ThemeProvider } from "@mui/material";

import { PageWrapper } from "../components/PageWrapper";
import { WorkWithFriendsRouter } from "./Router";
import { WorkWithFriendsProvider } from "./WorkWithFriendsContext";

export function WorkWithFriendsContainer() {
  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper>
        <WorkWithFriendsProvider>
          <WorkWithFriendsRouter />
        </WorkWithFriendsProvider>
      </PageWrapper>
    </ThemeProvider>
  );
}
