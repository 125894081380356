import { ModalRoute } from "../Navigation/ModalRoute";
import { ModalTransitionSwitch } from "../Navigation/ModalTransitionSwitch";
import AddFriendBottomSheet from "./modals/AddFriend/AddFriend";
import InviteBottomSheet from "./modals/Invite/Invite";
import RemoveFriendBottomSheet from "./modals/RemoveFriend/RemoveFriend";
import { VisibilitySettingsBottomSheet } from "./modals/VisibilitySettings/VisibilitySettings";
import {
  ADD_FRIEND_MODAL_PATH,
  INVITE_LINK_MODAL_PATH,
  REMOVE_FRIEND_MODAL_PATH,
  VISIBILITY_SETTINGS_MODAL_PATH,
} from "./paths";
/**
 * WorkWithFriendsModalRoutes handles all work with friends-related modals in the application.
 *
 * Key characteristics:
 * 1. Can be rendered independently or on top of workplace modals
 * 2. Uses baseNavigationPathOverride when rendered on top of workplace modals
 * 3. Shares common transition animations with other modals
 *
 * Common flows:
 * - Direct invite modal: /home-v2/account/friends/invite
 */
export function WorkWithFriendsModalRoutes() {
  return (
    <ModalTransitionSwitch>
      <ModalRoute
        shouldReplacePathOnBack
        closeModalPath="/home-v2/account/friends"
        path={ADD_FRIEND_MODAL_PATH}
        render={({ modalState }) => {
          return (
            <AddFriendBottomSheet
              modalState={modalState}
              onClose={() => {
                modalState.closeModal();
              }}
            />
          );
        }}
      />
      <ModalRoute
        shouldReplacePathOnBack
        closeModalPath="/home-v2/account/friends"
        path={INVITE_LINK_MODAL_PATH}
        render={({ modalState }) => {
          return (
            <InviteBottomSheet
              modalState={modalState}
              onClose={() => {
                modalState.closeModal();
              }}
            />
          );
        }}
      />
      <ModalRoute
        shouldReplacePathOnBack
        closeModalPath="/home-v2/account/friends"
        path={REMOVE_FRIEND_MODAL_PATH}
        render={({ modalState }) => {
          return (
            <RemoveFriendBottomSheet
              modalState={modalState}
              onClose={() => {
                modalState.closeModal();
              }}
            />
          );
        }}
      />
      <ModalRoute
        shouldReplacePathOnBack
        closeModalPath="/home-v2/account/friends"
        path={VISIBILITY_SETTINGS_MODAL_PATH}
        render={({ modalState }) => {
          return (
            <VisibilitySettingsBottomSheet
              modalState={modalState}
              onClose={() => {
                modalState.closeModal();
              }}
            />
          );
        }}
      />
    </ModalTransitionSwitch>
  );
}
