import { createContext, useMemo, useState } from "react";

import { type WorkerPublicProfile } from "./hooks/useGetWorkerPublicProfile";

export type SelectedTab = "friends" | "pending" | "invitations";

const WorkWithFriendsContext = createContext<{
  activeFriend?: WorkerPublicProfile;
  setActiveFriend: (friend: WorkerPublicProfile | undefined) => void;
  selectedTab: SelectedTab;
  setSelectedTab: (tab: SelectedTab) => void;
}>({
  activeFriend: undefined,
  setActiveFriend: () => {
    throw new Error("setActiveFriend is not implemented");
  },
  selectedTab: "friends",
  setSelectedTab: () => {
    throw new Error("setSelectedTab is not implemented");
  },
});

interface Props {
  children: React.ReactNode;
}

export function WorkWithFriendsProvider(props: Props) {
  const { children } = props;

  const [activeFriend, setActiveFriend] = useState<WorkerPublicProfile | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>("friends");

  const value = useMemo(
    () => ({ activeFriend, setActiveFriend, selectedTab, setSelectedTab }),
    [activeFriend, setActiveFriend, selectedTab, setSelectedTab]
  );

  return (
    <WorkWithFriendsContext.Provider value={value}>{children}</WorkWithFriendsContext.Provider>
  );
}

export default WorkWithFriendsContext;
