import { CbhIcon, ListItem, ListItemText } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import pluralize from "pluralize";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { type ShiftSlotCoalesced } from "../../Shift/Open/useGetShiftsSlotV2";
import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import { useIsWorkWithFriendsV2Enabled } from "../hooks/useIsWorkWithFriendsV2Enabled";
import { ADD_FRIEND_MODAL_PATH } from "../paths";
import FriendRow from "./FriendRow";

interface WorkingThisShiftListProps {
  shiftSlots?: ShiftSlotCoalesced;
  friendsMap?: Map<string, WorkerPublicProfile>;
}

export function WorkingThisShiftListItem(props: WorkingThisShiftListProps) {
  const { shiftSlots, friendsMap } = props;
  const history = useHistory();

  const availabilityText = useMemo(() => {
    const availableQualifications = Object.entries(shiftSlots?.availableQualifications ?? {});
    if (availableQualifications.length === 0) {
      return undefined;
    }

    return `Available for ${availableQualifications.reduce(
      (qualificationText, [qualification, count], index) => {
        let result = qualificationText;
        if (index === availableQualifications.length - 1 && availableQualifications.length > 1) {
          result += " and ";
        }

        result += `${count} ${pluralize(qualification, count)}`;

        if (index < availableQualifications.length - 2) {
          result += ", ";
        }

        return result;
      },
      ""
    )}`;
  }, [shiftSlots]);

  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();
  if (!isWorkWithFriendsV2Enabled) {
    return null;
  }

  return (
    <ListItem>
      <ListItemText
        disableTypography
        primary={
          <Stack direction="row" gap={2} width="100%">
            <Stack width="2rem" flexShrink={0}>
              <CbhIcon type="user-filled" />
            </Stack>
            <Stack flex={1} minWidth={0}>
              <Text
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                })}
                mb={2}
              >
                Working this shift
              </Text>
              <Stack>
                {isDefined(shiftSlots?.workers) && shiftSlots?.workers.length > 0 ? (
                  <>
                    {isDefined(availabilityText) && (
                      <Text
                        variant="body2"
                        sx={(theme) => ({
                          color: theme.palette.text.secondary,
                        })}
                      >
                        {availabilityText}
                      </Text>
                    )}
                    <Stack sx={{ mt: 6 }}>
                      {shiftSlots?.workers.map((worker) => (
                        <Stack key={worker.id} mb={6}>
                          <FriendRow
                            friend={worker}
                            actions={
                              isDefined(friendsMap) && !friendsMap.has(worker.id) ? (
                                <Button
                                  size="small"
                                  onClick={() => {
                                    history.push(
                                      ADD_FRIEND_MODAL_PATH.replace(":friendId", worker.id)
                                    );
                                  }}
                                >
                                  Add
                                </Button>
                              ) : undefined
                            }
                          />
                        </Stack>
                      ))}
                    </Stack>
                  </>
                ) : (
                  <Text
                    variant="body2"
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                    })}
                  >
                    Be the first to book this shift
                  </Text>
                )}
              </Stack>
            </Stack>
          </Stack>
        }
      />
    </ListItem>
  );
}
