import { Route, Switch } from "react-router-dom";

import { RootPaths } from "../../App/paths";
import { WORK_WITH_FRIENDS_PATH } from "../ShiftDiscovery/paths";
import { WorkWithFriendsModalRoutes } from "./ModalRoutes";
import { WorkWithFriendsPage } from "./Page";

export function WorkWithFriendsRouter() {
  return (
    <>
      <WorkWithFriendsModalRoutes />
      <Switch>
        <Route
          path={`${RootPaths.APP_V2_HOME}/${WORK_WITH_FRIENDS_PATH}`}
          component={WorkWithFriendsPage}
        />
      </Switch>
    </>
  );
}
